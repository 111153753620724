<template>
  <div class="GiftGrowth">
    <div class="GiftGrowth_top">
      <div class="growth_value">
        <div class="growth_value_lf">
          <p>我的成长值</p>
          <p>{{ growthValue }}</p>
        </div>
      </div>
      <div class="grade">
        <div
          class="round_line"
          :style="'transform:rotate(' + getDeg(grade) + ') translateZ(0)'"
        ></div>
        <div class="emblem">
          <div class="text" v-for="(text, index) in emblemText" :key="index">
            <div
              :class="{ current: index == grade, no_current: index > grade }"
            >
              {{ text }}
            </div>
          </div>
        </div>
      </div>
      <div class="me_growth_value_box">
        <div class="me_growth_value">
          <van-image
            fit="cover"
            position="left"
            :src="
              wxHeadimgurl ? wxHeadimgurl : require('@/assets/img/main.jpg')
            "
          />
          <p>
            今日成长值 <span>+{{ todayGrowthValue }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="GiftGrowth_bottom">
      <div class="GiftGrowth_bottom_box">
        <div class="GiftGrowth_nav">
          <van-tabs :active="active" @click="goQuiz">
            <van-tab
              v-for="element in gradeNavList"
              :title="element == '全部' ? element : `V${element}`"
              :key="element"
            >
              <div class="GiftGrowth_list">
                <div
                  class="GiftGrowth_list_box"
                  v-for="item in courseList"
                  :key="item.title"
                >
                  <div class="top">
                    <van-image
                      class="top_left_img"
                      fit="cover"
                      lazy-load
                      :src="
                        item.equityImageUrl
                          ? item.equityImageUrl
                          : require('@/assets/img/SignIn_03.png')
                      "
                    >
                    </van-image>

                    <div class="GiftGrowth_list_box_right">
                      <h3 class="van-multi-ellipsis--l2">
                        {{ item.growthEquityName }}
                      </h3>
                      <p class="van-multi-ellipsis--l2">
                        {{ item.equityContent }}
                      </p>
                      <!-- <p >领取等级： {{ item.require }}</p> -->
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="bottom_garden">
                      领取等级：<span>{{ item.garden }}</span>
                    </div>

                    <van-button
                      plain
                      hairline
                      round
                      type="primary"
                      size="small"
                      @click="goDetails(item)"
                      >领取</van-button
                    >
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <copyrightIp class="bac_grey" />
      </div>
    </div>

    <van-dialog
      v-model="show"
      title="课程领取"
      show-cancel-button
      confirmButtonText="确定领取"
      confirmButtonColor="#3988F8"
      @confirm="confirm"
    >
      <van-field v-model="value" placeholder="报名信息" />
    </van-dialog>
  </div>
</template>
<script>
import {
  Image as VanImagen,
  Tab,
  Tabs,
  Button,
  Dialog,
  Field,
  Notify,
} from "vant";

import {
  findUserIntegralGarden,
  exchangePrizeList,
  exchangePrize,
  theDayGetUpValue,
} from "@/api/SignIn";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [VanImagen.name]: VanImagen,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Notify.name]: Notify,
    copyrightIp,
  },
  data() {
    return {
      value: "",
      show: false,
      active: "",
      courseList: [
        {
          title: "中智薪税2022年2月专题课-个人所得税综合汇算清缴",
          require:
            "这里是简介 这里是简介 这里是简介 这里是简介这里是简介 这里是简介 这里是简介 这里…",
          num: 1,
        },
        {
          title: "中智薪税2022年2月专题课-劳务合同",
          require:
            "这里是简介 这里是简介 这里是简介 这里是简介这里是简介 这里是简介 这里是简介 这里…",
          num: 1,
        },
        {
          title:
            "中智薪税2022年2月专题课-高级人才缺口大，半数以上企业有3中智薪税2022年2月专题课-高级人才缺口大，半数以上",
          require:
            "这里是简介 这里是简介 这里是简介 这里是简介这里是简介 这里是简介 这里是简介 这里…",
          num: 1,
        },
      ],
      emblemText: ["v1", "v2", "v3", "v4", "v5", "v6", "v7"],
      wxHeadimgurl: "", //头像
      grade: "", //等级
      gradeString: "", // 判断是否符合领取等级等级V1
      growthValue: "", // 成长值
      todayGrowthValue: "", // 今日成长值
      gradeNavList: [], //等级导航
    };
  },
  mounted() {
    this.getFindUserIntegralGarden();
    this.getExchangePrizeList();
    this.getTheDayGetUpValue();
  },
  methods: {
    goQuiz(index, title) {
      if (title == "全部") {
        this.getExchangePrizeList();
      } else {
        this.getExchangePrizeList(title);
      }
    },
    //  奖品列表
    getExchangePrizeList(garden = "") {
      exchangePrizeList({ garden }).then((res) => {
        this.courseList = res.data.data;
        if (!this.gradeNavList.length) {
          this.courseList.forEach((item) => {
            let num = item.garden?.split("V")[1];
            if (!this.gradeNavList.includes(num)) this.gradeNavList.push(num);
          });
          this.gradeNavList = this.gradeNavList.sort((a, b) => a - b);
          this.gradeNavList.unshift("全部");
        }
      });
    },
    // 获取用户成长值 等级信息
    getFindUserIntegralGarden() {
      let openId = localStorage.getItem("code") || "";
      findUserIntegralGarden({ openId }).then((res) => {
        this.wxHeadimgurl = res.data.data.twxUser.wxHeadimgurl || "";
        this.gradeString = res.data.data.twxUser.garden;
        this.grade = Number(res.data.data.twxUser.garden?.split("V")[1]) - 1;
        this.growthValue = res.data.data.twxUser.userUpValue || "";
      });
    },
    // 今日成长值
    getTheDayGetUpValue() {
      let openId = localStorage.getItem("code") || "";
      theDayGetUpValue({ openId }).then((res) => {
        this.todayGrowthValue = res.data.data;
      });
    },
    getDeg(index) {
      return index * 22 - 157 + "deg"; //一个圆是360度这里划分成了12份。每个文字角度是30度。然后从第一个文字开始距离水平线是倾斜了15度的
    },
    confirm() {
      this.$router.push("/signIn/giftGrowth/Result");
    },
    goDetails(data) {
      let equityid = data.eid;
      let gardenId = data.gid;
      let openId = localStorage.getItem("code") || "";
      let linkUrl = data.linkUrl || "";
      let recourceId = linkUrl?.split("video/")[1]?.split("?type")[0];

      exchangePrize({ equityid, gardenId, openId, recourceId }).then((res) => {
        switch (res.data.data) {
          case 1001:
            Notify("已兑换过该奖品");
            Dialog.confirm({
              message: "是否观看课程",
            })
              .then(() => {
                window.location.href = linkUrl;
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
            break;
          case 0:
            Notify("兑换失败");
            break;
          case 1:
            Notify({ type: "success", message: "兑换成功" });
            Dialog.confirm({
              message: "是否观看课程",
            })
              .then(() => {
                window.location.href = linkUrl;
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
            // this.$router.push("/SignIn/GiftGrowth/Details");
            break;
          case 2:
            Notify("用户等级不够");
            break;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bac_grey {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #f6f8f8;
}
.GiftGrowth {
  min-height: 100%;
}
.GiftGrowth_top {
  width: 100%;
  height: 195px;
  background: #24262e;
  .growth_value {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 15px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    .growth_value_lf {
      background: #f6d497;
      color: #333;
      text-align: center;
      padding: 1px 5px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 0 10px 10px 0;
    }
    .growth_value_ri {
      font-size: 12px;
      background: #65676c;
      border-radius: 15px 0 0 15px;
      padding: 0px 5px;
      line-height: 32px;
      &:nth-child(1) {
        margin-bottom: 8px;
      }
    }
  }
  .grade {
    width: 100%;
    height: 143px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background: #24262e;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      bottom: -3px;
      left: 58px;
    }
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background: #24262e;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      bottom: -3px;
      right: 58px;
    }
    .round_line {
      position: absolute;
      top: 52px;
      width: 270px;
      height: 135px;
      border: 2px solid #e6c891;
      border-radius: 50% 50% 0 0/100% 100% 0 0;
      border-bottom: none;
      transform-origin: center bottom;
      transform: rotate(-157deg) translateZ(0);
      transition: 1s;
      z-index: 1;
    }
    .emblem {
      position: absolute;
      top: 52px;
      width: 270px;
      height: 135px;
      border: 2px solid #3b3d44;
      border-radius: 50% 50% 0 0/100% 100% 0 0;
      border-bottom: none;
      color: #8e9ba2;
      text-align: center;
      .text {
        display: flex;
        justify-content: center;
        position: absolute;
        color: #e6c891;
        left: -6px;
        right: 0;
        top: -34px;
        bottom: 0;
        font-size: 12px;
        transition: all 0.5s cubic-bezier(0, 0, 0, 1);
        transform-origin: 140px 168px;
        &:nth-child(1) {
          top: -32px;
        }
        &::before {
          content: "";
          width: 7px;
          height: 7px;
          background: #e6c891;
          border-radius: 50%;
          position: absolute;
          z-index: 10;
          top: 29px;
          left: 134px;
        }

        .current {
          width: 22px;
          height: 22px;
          background: #e6c891;
          border-radius: 50%;
          line-height: 22px;
          color: #24262e;
          font-weight: bold;
          position: relative;
          &::after {
            content: "";
            width: 26px;
            height: 26px;
            border: 1px solid #e6c891;
            position: absolute;
            top: -3px;
            left: -3px;
            border-radius: 50%;
          }
        }
        .no_current {
          color: #fff;
        }
      }
    }
    @for $i from 1 through 7 {
      .text:nth-of-type(#{$i}) {
        transform: rotate(($i - 1) * 22 - 65 + deg) translateZ(0);
      }
    }
  }
  .me_growth_value_box {
    position: relative;
    padding-bottom: 20px;
    .me_growth_value {
      width: 100%;
      text-align: center;
      position: absolute;
      top: -60px;
      left: 0;
      color: #fff;
      .van-image {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        overflow: hidden;
        margin: 10px 0;
      }
      p {
        color: #a3a3a6;
        span {
          color: #e6c891;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}

.GiftGrowth_bottom {
  width: 100%;
  min-height: calc(100% - 242px);
  position: relative;
  .GiftGrowth_bottom_box {
    width: 100%;
    min-height: 487px;
    padding-bottom: 50px;
    background: #fff;
    overflow: hidden;
    position: absolute;
    top: -15px;
    left: 0;
    border-radius: 10px 10px 0 0;
    .GiftGrowth_nav {
      width: 100%;
      height: calc(100% - 44px);
      display: flex;
      // padding: 5px 0;
      justify-content: space-around;
      // background: #f9f9f9;
      // font-weight: bold;
      .van-tabs {
        width: 100%;
      }
      .GiftGrowth_list {
        width: 100%;
        border-top: 1px solid #e2e2e2;
        background: #f6f8f8;
        .GiftGrowth_list_box {
          background: #fff;
          padding: 20px 15px 5px 15px;
          margin-bottom: 10px;
          .top {
            width: 100%;
            display: flex;
            .top_left_img {
              width: 94px;
              height: 72px;
              min-width: 94px;
              border-radius: 8px;
              overflow: hidden;
            }
            .GiftGrowth_list_box_right {
              flex: 1;
              padding-left: 10px;
              h3 {
                font-size: 16px;
                margin: 6px 0 10px 0;
              }
              p {
                font-size: 12px;
              }
            }
          }
          .bottom {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 7px 0;
            .bottom_garden {
              padding-right: 15px;
            }
            span {
              color: #1989fa;
            }
            .van-button {
              border-color: #1989fa;
              padding: 0 17px;
            }
          }
        }
      }
    }
  }
}
</style>
